import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import ReactGA from "react-ga";
import Service from "../../api/Service.js";
import Navigation from "../App/Navigation";
import CheckItem from "./CheckItem";
import LoadingSpinner from "../App/LoadingSpinner";
import FilterItemsForm from "../App/FilterItemsForm";
import FilterItems from "../App/FilterItems";
import { updateItemInModel } from "../../Utils";
import "./Check.scss";

class Check extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modelCheck: [],
      originalModelCheck: [],
      isLoading: true,
      searchQuery: "",
    };
  }

  async componentDidMount() {
    document.title = global.config.app.name + " - Check";
    ReactGA.pageview("/check");
    await this.getModelCheck();
  }

  async getModelCheck() {
    let modelCheck = await Service.getModelCheck(
      this.props.user.uid,
      this.props.user.list.id
    );
    this.setState({
      modelCheck: modelCheck,
      originalModelCheck: modelCheck,
      isLoading: false,
    });
    this.handleSearch();
  }

  setSearchQuery = (searchQuery) => {
    searchQuery = searchQuery.toLowerCase();
    this.setState(
      {
        searchQuery: searchQuery,
      },
      () => this.handleSearch()
    );
  };

  handleSearch = () => {
    if (this.state.searchQuery.length) {
      const output = FilterItems(
        this.state.originalModelCheck,
        this.state.searchQuery
      );
      this.setState({
        modelCheck: output,
      });
    } else {
      this.setState({
        modelCheck: this.state.originalModelCheck,
      });
    }
  };

  checkItem = (newItem, checkState, groupId) => {
    let newStateChecked = checkState ? 1 : 0;
    let updatedItem = { ...newItem, checked: newStateChecked };
    Service.updateItem(updatedItem, this.props.user.uid)
      .then((response) => {
        if (response.error) {
          // Error from API
          // TODO: error management in React
          console.error(response); // TODO remove this logging
          alert("Error from API: " + response.message);
        } else {
          // Update current state
          const updatedModelCheck = updateItemInModel(
            this.state.modelCheck,
            groupId,
            newItem,
            updatedItem
          );
          this.setState({
            modelCheck: updatedModelCheck,
          });
          // Update current state
          const updatedOriginalModelCheck = updateItemInModel(
            this.state.originalModelCheck,
            groupId,
            newItem,
            updatedItem
          );
          this.setState({
            originalModelCheck: updatedOriginalModelCheck,
          });
        }
      })
      .catch((err) => {
        // TODO handle this
        console.error(err);
        this.setState({
          isLoading: false,
        });
      });
  };

  resetList = (event) => {
    event.preventDefault();
    let consent = window.confirm("Reset list?");
    if (consent) {
      Service.resetModelCheck(this.props.user.uid, this.props.user.list.id)
        .then((response) => {
          if (response.error) {
            // Error from API
            // TODO: error management in React
            console.error(response); // TODO remove this logging
            alert("Error from API: " + response.message);
          } else {
            // No error from API, update state
            this.getModelCheck();
          }
        })
        .catch((err) => {
          // TODO handle this
          console.error(err);
          this.setState({
            isLoading: false,
          });
        });
    }
  };

  render() {
    return (
      <div id="model-check-wrapper">
        <Navigation />
        <div className="row">
          <div className="col">
            {this.state.isLoading === false ? (
              <React.Fragment>
                {this.state.modelCheck.length ||
                this.state.searchQuery.length ? (
                  <React.Fragment>
                    <FilterItemsForm setSearchQuery={this.setSearchQuery} />
                    <ul className="list-wrapper">
                      {this.state.modelCheck.map((group, groupIndex) => {
                        return (
                          <li key={group.id} className="group-wrapper">
                            <div className="group-name">{group.name}</div>
                            <ul>
                              {group.items.map((item, idx) => {
                                return (
                                  <CheckItem
                                    key={idx}
                                    item={item}
                                    groupId={group.id}
                                    checkItem={this.checkItem}
                                  />
                                );
                              })}
                            </ul>
                          </li>
                        );
                      })}
                    </ul>
                    <div className="contextual-menu-footer">
                      <button
                        className="btn btn-primary"
                        onClick={(event) => this.resetList(event)}
                      >
                        Reset list
                      </button>
                    </div>
                  </React.Fragment>
                ) : (
                  <div className="body-wrapper">
                    <p>No items to check.</p>
                    <p>
                      Add items in <Link to="/edit">edit mode</Link>
                    </p>
                  </div>
                )}
              </React.Fragment>
            ) : (
              <LoadingSpinner />
            )}
          </div>
        </div>
      </div>
    );
  }
}

Check.propTypes = {
  user: PropTypes.object.isRequired,
};

export default Check;
