import React, { useEffect } from "react";
import PropTypes from "prop-types";
import ReactGA from "react-ga";
import LoginButton from "../App/LoginButton";
import Navigation from "../App/Navigation";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faCheckSquare } from "@fortawesome/free-solid-svg-icons";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { Redirect } from "react-router-dom";

import "./Welcome.scss";

library.add(faCheckSquare, faCheckCircle);

const Welcome = (props) => {
  useEffect(() => {
    document.title = global.config.app.name;
    ReactGA.pageview("/welcome");
  }, []);

  return (
    <div id="welcome-wrapper">
      <div className="row">
        <div className="col">
          {props.userIsLoggedIn && <Navigation />}
          {props.userIsLoggedIn ? (
            <Redirect to="/edit" />
          ) : (
            <div className="body-wrapper welcome-wrapper-inner">
              <h2 className="display-4">Listig</h2>
              <p className="lead">
                Todo-system for recurring list, specifically grocery-shopping.
              </p>
              <hr className="my-4" />
              <p>
                <FontAwesomeIcon icon={faCheckCircle} /> Add groups
                and items in edit mode.
              </p>
              <p>
                <FontAwesomeIcon icon={faCheckSquare} /> Tick them off
                on check mode.
              </p>
              <LoginButton
                loginSuccess={props.loginSuccess}
                loginFailure={props.loginFailure}
                loginLabel="Sign in with Google"
                id="login-button-welcome"
                classNames="btn-lg"
              />
              <hr className="my-4" />
              <h3>Coming features</h3>
              <ul>
                <li>- Multiple lists</li>
                <li>- Localisation</li>
              </ul>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

Welcome.propTypes = {
  userIsLoggedIn: PropTypes.bool.isRequired,
  loginSuccess: PropTypes.func.isRequired,
  loginFailure: PropTypes.func.isRequired,
};

export default Welcome;
