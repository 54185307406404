export const getGroupIndex = (data, groupId) => {
  for (const [key, value] of Object.entries(data)) {
    if (value.id === groupId) {
      return key;
    }
  }
};

export const updateItemInModel = (modelData, groupId, newItem, updatedItem) => {
  const groupIndex = getGroupIndex(modelData, groupId);
  const itemIndex = modelData[groupIndex]["items"].findIndex(
    (item) => newItem.id === item.id
  );
  let updatedModelData = [...modelData];
  updatedModelData[groupIndex]["items"][itemIndex] = updatedItem;
  return updatedModelData;
};
