import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import ReactGA from "react-ga";
import Navigation from "../App/Navigation";
import LogoutButton from "../App/LogoutButton";

import "./Home.scss";


const Home = (props) => {
  useEffect(() => {
    document.title = global.config.app.name + " - Home";
    ReactGA.pageview("/home");
  }, []);

  return (
    <div id="home-wrapper">
      <Navigation />
      <div className="row">
        <div className="col">
          <div className="body-wrapper">
            <h2>Home</h2>
            <p>Welcome {props.user.name}</p>
            <p>
              <Link to="/edit">
                <b>Start by editing your list</b>
              </Link>
            </p>
            <hr />
            <p>
              <Link to="/profile">See your profile</Link>
            </p>
            <p>
              <LogoutButton logout={props.logout} id="logout-button-home" />
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

Home.propTypes = {
  user: PropTypes.object.isRequired,
  logout: PropTypes.func.isRequired,
};

export default Home;
