import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import PropTypes from "prop-types";

const SearchEdit = (props) => {
  const [currentState, setCurrentState] = useState({
    query: "",
  });

  let handleChange = (event) => {
    event.preventDefault();
    const queryString = event.target.value.trim();
    setCurrentState({
      query: queryString,
    });
    props.setSearchQuery(queryString);
  };

  const clearFilter = (event) => {
    event.preventDefault();
    setCurrentState({
      query: "",
    });
    props.setSearchQuery("");

  };

  return (
    <div className="filter-items-wrapper">
      <div className="btn-group">
        <Form.Control
          type="text"
          className="filter-items-input"
          placeholder="Search"
          value={currentState.query}
          onChange={handleChange}
        />
        <span onClick={clearFilter} id="filter-items-input-clear">&times;</span>
      </div>
    </div>
  );
};

SearchEdit.propTypes = {
  setSearchQuery: PropTypes.func,
};

export default SearchEdit;
