import React, { useState } from "react";
import PropTypes from "prop-types";
import AddItem from "./AddItem";

const EditGroup = (props) => {
  const [displayAddItem, setDisplayAddItem] = useState(false);

  const toggleDisplayAddItem = () => {
    setDisplayAddItem(!displayAddItem);
  };

  return (
    <React.Fragment>
      <div className="group-name">
        <div className="inner">
        <span className="name">{props.group.name}</span>{" "}
          <span
            className="remove-item"
            onClick={() => props.removeGroup(props.group)}
          >
            &times;
          </span>
          <span
            className="add-item-toggle"
            onClick={() => toggleDisplayAddItem()}
          >
            <b>+</b> Add item
          </span>
        </div>
      </div>
      <AddItem
        groupIndex={props.groupIndex}
        groupId={props.group.id}
        addItem={props.addItem}
        display={displayAddItem}
        toggleDisplayAddItem={toggleDisplayAddItem}
      />
    </React.Fragment>
  );
};

EditGroup.propTypes = {
  group: PropTypes.object.isRequired,
  groupIndex: PropTypes.number.isRequired,
  removeGroup: PropTypes.func.isRequired,
  addItem: PropTypes.func.isRequired,
};

export default EditGroup;
