import React from "react";
import PropTypes from "prop-types";
import Button from "react-bootstrap/Button";
import { GoogleLogout } from "react-google-login";

const API_CLIENT_ID = process.env.REACT_APP_GOOGLE_API_CLIENT_ID;

const LogoutButton = (props) => {
  return (
    <span>
      <GoogleLogout
        clientId={API_CLIENT_ID}
        onLogoutSuccess={props.logout}
        render={(renderProps) => (
          <Button
            onClick={renderProps.onClick}
            disabled={renderProps.disabled}
            variant="primary"
            size="md"
            id={props.id}
          >
            Logout
          </Button>
        )}
      />
    </span>
  );
};

LogoutButton.propTypes = {
  logout: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
};

export default LogoutButton;
