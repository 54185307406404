import React from "react";
import { NavLink } from "react-router-dom";

const Navigation = () => {
  return (
    <div className="navigation-wrapper">
      <div className="row">
        <div className="col">
          <NavLink
            to="/check"
            activeClassName="active"
            className="navigation-item navigation-item-check"
          >
            Check
          </NavLink>
          <NavLink
            to="/edit"
            activeClassName="active"
            className="navigation-item navigation-item-edit"
          >
            Edit
          </NavLink>
        </div>
      </div>
    </div>
  );
};

export default Navigation;
