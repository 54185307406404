import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Spinner from "react-bootstrap/Spinner";

const LoadingSpinner = () => {
  return (
    <React.Fragment>
      <div id="loading-spinner-wrapper">
        <Row>
          <Col className="text-center">
            <Spinner
              animation="border"
              role="status"
              size="lg"
              variant="primary"
            />
            <p className="pt-3">Loading</p>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};

export default LoadingSpinner;
