import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

const AddItem = (props) => {
  const [currentState, setCurrentState] = useState({
    name: "",
  });

  useEffect(() => {
    return () => {
      // Empty input by setting new state
      setCurrentState({ name: "" });
    };
  }, [props.display]);

  let handleChange = (event) => {
    event.preventDefault();
    const value = event.target.value;
    setCurrentState({
      name: value,
    });
  };

  let submitForm = (event) => {
    event.preventDefault();
    document.activeElement.blur();
    props.addItem(currentState.name, props.groupId);
    props.toggleDisplayAddItem();
    return false;
  };

  if (props.display) {
    return (
      <div
        className="add-item-wrapper"
        id={"add-item-wrapper-" + props.groupIndex}
      >
        <form onSubmit={(event) => submitForm(event)}>
          <table className="add-item-table">
            <tbody>
              <tr>
                <td className="add-item-input-cell">
                  <Form.Control
                    type="text"
                    className="add-item-input"
                    value={currentState.name}
                    onChange={handleChange}
                    autoFocus
                  />
                </td>
                <td className="add-item-button-cell">
                  <Button
                    type="submit"
                    className="add-item-button"
                    onClick={(event) => submitForm(event)}
                  >
                    <span className="d-sm-none">Add</span>
                    <span className="d-none d-sm-block">Add to group</span>
                  </Button>
                </td>
                <td className="add-item-cancel-cell">
                  <Button
                    variant="dark-outline"
                    className="add-item-cancel"
                    onClick={props.toggleDisplayAddItem}
                  >
                    Cancel
                  </Button>
                </td>
              </tr>
            </tbody>
          </table>
        </form>
      </div>
    );
  } else {
    return null;
  }
};

AddItem.propTypes = {
  groupIndex: PropTypes.number.isRequired,
  groupId: PropTypes.string.isRequired,
  display: PropTypes.bool.isRequired,
  toggleDisplayAddItem: PropTypes.func.isRequired,
  addItem: PropTypes.func.isRequired,
};

export default AddItem;
