import React, { Component } from "react";
import PropTypes from "prop-types";
import ReactGA from "react-ga";
import Service from "../../api/Service.js";
import Navigation from "../App/Navigation";
import AddGroup from "./AddGroup";
import EditGroup from "./EditGroup";
import EditItem from "./EditItem";
import LoadingSpinner from "../App/LoadingSpinner";
import FilterItemsForm from "../App/FilterItemsForm";
import FilterItems from "../App/FilterItems";
import { updateItemInModel } from "../../Utils";
import "./Edit.scss";

class Edit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modelEdit: [],
      originalModelEdit: [],
      showAddGroup: false,
      isLoading: true,
      searchQuery: "",
    };
  }

  async componentDidMount() {
    document.title = global.config.app.name + " - Edit";
    ReactGA.pageview("/edit");
    await this.getModelEdit();
  }

  async getModelEdit() {
    let modelEdit = await Service.getModelEdit(
      this.props.user.uid,
      this.props.user.list.id
    );
    this.setState({
      modelEdit: modelEdit,
      originalModelEdit: modelEdit,
      showAddGroup: true,
      isLoading: false,
    });
    this.handleSearch();
  }

  activateItem = (newItem, checkState, groupId) => {
    let newStateActivated = checkState ? 1 : 0;
    let newStateChecked = newItem.checked;
    if (newStateActivated === 0) {
      newStateChecked = 0;
    }
    let updatedItem = {
      ...newItem,
      active: newStateActivated,
      checked: newStateChecked,
    };
    Service.updateItem(updatedItem, this.props.user.uid)
      .then((response) => {
        if (response.error) {
          // Error from API
          // TODO: error management in React
          console.error(response); // TODO remove this logging
          this.setState({
            isLoading: false,
          });
          alert("Error from API: " + response.message);
        } else {
          // Update current state
          const updatedModelEdit = updateItemInModel(
            this.state.modelEdit,
            groupId,
            newItem,
            updatedItem
          );
          this.setState({
            modelEdit: updatedModelEdit,
          });
          // Update original state
          const updatedOriginalModelEdit = updateItemInModel(
            this.state.originalModelEdit,
            groupId,
            newItem,
            updatedItem
          );
          this.setState({
            originalModelEdit: updatedOriginalModelEdit,
          });
        }
      })
      .catch((err) => {
        // TODO handle this
        console.error(err);
        this.setState({
          isLoading: false,
        });
      });
  };

  addItem = (name, groupId) => {
    if (name.length) {
      Service.addItem(
        name,
        groupId,
        this.props.user.uid,
        this.props.user.list.id
      )
        .then((response) => {
          if (response.error) {
            console.error(response); // TODO remove this logging
            this.setState({
              isLoading: false,
            });
            alert("Error from API: " + response.message);
          } else {
            this.getModelEdit();
          }
        })
        .catch((err) => {
          // TODO handle this
          console.error(err);
          this.setState({
            isLoading: false,
          });
        });
    } else {
      return false;
    }
  };

  removeItem = (item) => {
    let consent = window.confirm("Delete '" + item.name + "'?");
    if (consent) {
      const id = item.id;
      Service.deleteItem(id, this.props.user.uid)
        .then((response) => {
          if (response.error) {
            console.error(response); // TODO remove this logging
            this.setState({
              isLoading: false,
            });
            alert("Error from API: " + response.message);
            this.getModelEdit();
          } else {
            // TODO Run getModelEdit OR slice the state?
            this.getModelEdit();
          }
        })
        .catch((err) => {
          // TODO handle this
          console.error(err);
          this.setState({
            isLoading: false,
          });
        });
    } else {
      document.activeElement.blur();
    }
  };

  addGroup = (name) => {
    if (name.length) {
      Service.addGroup(name, this.props.user.uid, this.props.user.list.id)
        .then((response) => {
          if (response.error) {
            console.error(response); // TODO remove this logging
            this.setState({
              isLoading: false,
            });
            alert("Error from API: " + response.message);
          } else {
            this.getModelEdit();
          }
        })
        .catch((err) => {
          // TODO handle this
          console.error(err);
          this.setState({
            isLoading: false,
          });
        });
    } else {
      return false;
    }
  };

  removeGroup = (group) => {
    let consent = window.confirm(
      "Delete '" + group.name + "' and all items in group?"
    );
    if (consent) {
      const id = group.id;
      Service.deleteGroup(id, this.props.user.uid)
        .then((response) => {
          if (response.error) {
            console.error(response); // TODO remove this logging
            this.setState({
              isLoading: false,
            });
            alert("Error from API: " + response.message);
            this.getModelEdit();
          } else {
            // TODO Run getModelEdit OR slice the state?
            this.getModelEdit();
          }
        })
        .catch((err) => {
          // TODO handle this
          console.error(err);
          this.setState({
            isLoading: false,
          });
        });
    } else {
      document.activeElement.blur();
    }
  };

  setSearchQuery = (searchQuery) => {
    searchQuery = searchQuery.toLowerCase();
    this.setState(
      {
        searchQuery: searchQuery,
      },
      () => this.handleSearch()
    );
  };

  handleSearch = () => {
    if (this.state.searchQuery.length) {
      const output = FilterItems(
        this.state.originalModelEdit,
        this.state.searchQuery
      );
      this.setState({
        modelEdit: output,
      });
    } else {
      this.setState({
        modelEdit: this.state.originalModelEdit,
      });
    }
  };

  render() {
    return (
      <div id="model-edit-wrapper">
        <Navigation />
        <div className="row">
          <div className="col">
            {this.state.isLoading === false ? (
              <React.Fragment>
                <FilterItemsForm setSearchQuery={this.setSearchQuery} />
                <ul className="list-wrapper">
                  {this.state.modelEdit.map((group, groupIndex) => {
                    return (
                      <li key={group.id} className="group-wrapper">
                        <EditGroup
                          group={group}
                          groupIndex={groupIndex}
                          removeGroup={this.removeGroup}
                          addItem={this.addItem}
                        />
                        {group.items.length ? (
                          <ul>
                            {group.items.map((item, idx) => {
                              return (
                                <EditItem
                                  key={idx}
                                  item={item}
                                  groupId={group.id}
                                  removeItem={this.removeItem}
                                  activateItem={this.activateItem}
                                />
                              );
                            })}
                          </ul>
                        ) : (
                          <React.Fragment></React.Fragment>
                        )}
                      </li>
                    );
                  })}
                </ul>
                {this.state.showAddGroup && (
                  <div className="contextual-menu-footer">
                    <AddGroup addGroup={this.addGroup} />
                  </div>
                )}
              </React.Fragment>
            ) : (
              <LoadingSpinner />
            )}
          </div>
        </div>
      </div>
    );
  }
}

Edit.propTypes = {
  user: PropTypes.object.isRequired,
};

export default Edit;
