import React from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faCheckSquare } from "@fortawesome/free-solid-svg-icons";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { faSquare } from "@fortawesome/free-regular-svg-icons";
import { faCircle } from "@fortawesome/free-regular-svg-icons";

library.add(faCheckSquare, faCheckCircle, faSquare, faCircle);

const CheckBox = (props) => {
  const onCheckChanged = (event) => {
    event.stopPropagation();
    const checked = props.checked;
    props.onChange(props.item, !checked, props.groupId);
  };

  const checked = props.checked;
  const icon = checked ? props.iconChecked : props.iconUnchecked;

  return (
    <span className="icon-checkbox" onClick={onCheckChanged}>
      <FontAwesomeIcon icon={icon} />
      <input
        type="checkbox"
        className="hidden-checkbox"
        name="fakeCheckbox"
        checked={checked || false}
        readOnly
      />
    </span>
  );
};

CheckBox.propTypes = {
  checked: PropTypes.number.isRequired,
  item: PropTypes.object.isRequired,
  groupId: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  iconChecked: PropTypes.array,
  iconUnchecked: PropTypes.array,
};

CheckBox.defaultProps = {
  iconChecked: ["fas", "check-square"],
  iconUnchecked: ["far", "square"],
};

export default CheckBox;
