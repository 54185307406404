import axios from "axios";
import qs from "querystring";

const API_URL_BASE = process.env.REACT_APP_API_URL_BASE;
const X_HEADER = "X-Listig-UID";

class Service {
  //
  //  User
  //
  async getUser(auth_uid) {
    let config = {
      method: "get",
      url: API_URL_BASE + "/user/get",
      headers: {},
      params: {
        auth_uid: auth_uid,
      },
    };
    return await axios(config)
      .then((response) => {
        //console.log("data from getUser: ", response.data);
        return response.data;
      })
      .catch((error) => {
        console.log(error);
      });
  }

  //
  // Models
  //
  async getModelCheck(uid, list) {
    let config = {
      method: "get",
      url: API_URL_BASE + "/model/check?list=" + list,
      headers: {
        [X_HEADER]: uid,
      },
    };
    return await axios(config)
      .then((response) => {
        //console.log("data from getModelCheck: ", response.data);
        return response.data;
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async resetModelCheck(uid, list) {
    let config = {
      method: "get",
      url: API_URL_BASE + "/model/resetchecked?list=" + list,
      headers: {
        [X_HEADER]: uid,
      },
    };
    return await axios(config)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async getModelEdit(uid, list) {
    let config = {
      method: "get",
      url: API_URL_BASE + "/model/edit?list=" + list,
      headers: {
        [X_HEADER]: uid,
      },
    };
    return await axios(config)
      .then((response) => {
        //console.log("data from getModelEdit: ", response.data);
        return response.data;
      })
      .catch((error) => {
        console.log(error);
      });
  }

  //
  // Item
  //
  async deleteItem(id, uid) {
    let config = {
      method: "POST",
      url: API_URL_BASE + "/item/delete",
      headers: {
        [X_HEADER]: uid,
      },
      data: qs.stringify({
        id: id,
      }),
    };
    return await axios(config)
      .then(function (response) {
        //console.log("Response from Service.deleteItem", response.data);
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  async updateItem(item, uid) {
    let config = {
      method: "POST",
      url: API_URL_BASE + "/item/update",
      headers: {
        [X_HEADER]: uid,
      },
      data: qs.stringify({
        id: item.id,
        name: item.name,
        active: item.active,
        checked: item.checked,
      }),
    };
    return await axios(config)
      .then(function (response) {
        //console.log("Response from Service.updateItem", response.data);
        return response.data;
      })
      .catch(function (error) {
        //TODO test sending 404 from API and check catch
        console.log(error);
      });
  }

  async addItem(name, groupId, uid, list) {
    let config = {
      method: "POST",
      url: API_URL_BASE + "/item/add",
      headers: {
        [X_HEADER]: uid,
      },
      data: qs.stringify({
        name: name,
        group: groupId,
        list: list,
      }),
    };
    return await axios(config)
      .then(function (response) {
        //console.log("Response from Service.addItem", response.data);
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  //
  //  Group
  //
  async deleteGroup(id, uid) {
    let config = {
      method: "POST",
      url: API_URL_BASE + "/group/delete",
      headers: {
        [X_HEADER]: uid,
      },
      data: qs.stringify({
        id: id,
      }),
    };
    return await axios(config)
      .then(function (response) {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  async addGroup(name, uid, list) {
    let config = {
      method: "POST",
      url: API_URL_BASE + "/group/add",
      headers: {
        [X_HEADER]: uid,
      },
      data: qs.stringify({
        name: name,
        list: list,
      }),
    };
    return await axios(config)
      .then(function (response) {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  //
  //  List
  //
  async listExist(uid, list) {
    let config = {
      method: "get",
      url: API_URL_BASE + "/list/exist",
      headers: {
        [X_HEADER]: uid,
      },
      params: {
        id: list,
      },
    };
    return await axios(config)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async listLists(uid) {
    let config = {
      method: "get",
      url: API_URL_BASE + "/list/list",
      headers: {
        [X_HEADER]: uid,
      }
    };
    return await axios(config)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log(error);
      });
  }

}

export default new Service();
