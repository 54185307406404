import React from "react";
import UserContext from "./UserContext";

const UserProvider = (props) => {
  
  return (
    <UserContext.Provider
      value={{
        uid: props.value.uid,
        auth_uid: props.value.auth_uid,
        name: props.value.name,
        email: props.value.email,
        avatar: props.value.avatar,
      }}
    >
      {props.children}
    </UserContext.Provider>
  );
};

export default UserProvider;
