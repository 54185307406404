import React, { useEffect } from "react";
import { Link } from "react-router-dom";

const NotFound404 = () => {
  useEffect(() => {
    document.title = global.config.app.name + " - 404 Not found";
  }, []);

  return (
    <div id="notfound-wrapper">
      <div className="row">
        <div className="col">
          <div className="body-wrapper">
            <h2>404 - Not found</h2>
            <p>
              <Link to="/">Back to homepage</Link>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotFound404;
