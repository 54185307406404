import React from "react";
import PropTypes from "prop-types";
import CheckBox from "../App/CheckBox";

const CheckItem = (props) => {
  return (
    <li key={props.item.id} className="item-wrapper">
      <div className="inner">
        <CheckBox
          item={props.item}
          checked={props.item.checked}
          groupId={props.groupId}
          onChange={props.checkItem}
          iconChecked={["fas", "check-square"]}
          iconUnchecked={["far", "square"]}
        />{" "}
        <span>{props.item.name}</span>
      </div>
    </li>
  );
};

CheckItem.propTypes = {
  item: PropTypes.object.isRequired,
  groupId: PropTypes.string.isRequired,
  checkItem: PropTypes.func.isRequired,
};

export default CheckItem;
