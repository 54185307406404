import React from "react";
import PropTypes from "prop-types";
import GoogleLogin from "react-google-login";
import Button from "react-bootstrap/Button";

const API_CLIENT_ID = process.env.REACT_APP_GOOGLE_API_CLIENT_ID;

//
//  https://dev.to/sivaneshs/add-google-login-to-your-react-apps-in-10-mins-4del
//  https://github.com/partnerhero/gapi-script 
//  https://stackoverflow.com/questions/39097440/on-react-router-how-to-stay-logged-in-state-even-page-refresh
//

const LoginButton = (props) => {
  return (
    <React.Fragment>
      <GoogleLogin
        clientId={API_CLIENT_ID}
        onSuccess={props.loginSuccess}
        onFailure={props.loginFailure}
        isSignedIn={true}
        cookiePolicy={"single_host_origin"}
        prompt="select_account"
        render={(renderProps) => (
          <Button
            onClick={renderProps.onClick}
            disabled={renderProps.disabled}
            variant="primary"
            className={props.classNames}
            id={props.id}
          >
            {props.loginLabel}
          </Button>
        )}
      />
    </React.Fragment>
  );
};

LoginButton.propTypes = {
  loginSuccess: PropTypes.func.isRequired,
  loginFailure: PropTypes.func.isRequired,
  loginLabel: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  classNames: PropTypes.string,
};


LoginButton.defaultProps = {
  classNames: "btn-md",
};
export default LoginButton;
