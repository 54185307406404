import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import Image from "react-bootstrap/Image";
import LoginButton from "./LoginButton";

const Header = (props) => {
  let homeLink = props.userIsLoggedIn ? "/home" : "/";
  return (
    <div id="header">
      <div className="row">
        <div className="col">
          <Link to={homeLink} id="logo-header-link">
            <Image src="logo512.png" id="logo-header-image" alt="Listig logo" />
          </Link>
          <h1>Listig</h1>
        </div>
        <div className="col text-right">
          {props.userIsLoggedIn ? (
            <span>
              <Link to="/profile">
                <Image
                  roundedCircle
                  className="profile-avatar"
                  src={props.user.avatar}
                  alt="Profile avatar"
                  referrerPolicy="no-referrer"
                />
              </Link>
            </span>
          ) : (
            <span>
              <LoginButton
                loginSuccess={props.loginSuccess}
                loginFailure={props.loginFailure}
                loginLabel={props.loginLabel}
                id="login-button-header"
              />
            </span>
          )}
        </div>
      </div>
    </div>
  );
};

Header.propTypes = {
  user: PropTypes.object.isRequired,
  userIsLoggedIn: PropTypes.bool.isRequired,
  loginSuccess: PropTypes.func.isRequired,
  loginFailure: PropTypes.func.isRequired,
  loginLabel: PropTypes.string.isRequired,
};

export default Header;
