import React from "react";
import UserContext from "../../context/UserContext";

const Footer = (props) => {
  return (
    <UserContext.Consumer>
      {(value) => (
        <div id="footer">
          <div className="row">
            <div className="col">
              <span>{value.name}</span>
            </div>
            <div className="col text-right">
              <a href="#top">Top of page</a>
            </div>
          </div>
        </div>
      )}
    </UserContext.Consumer>
  );
};

export default Footer;
