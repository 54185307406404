import React, { useEffect } from "react";
import PropTypes from "prop-types";
import ReactGA from "react-ga";
import Image from "react-bootstrap/Image";
import LogoutButton from "../App/LogoutButton";
import Navigation from "../App/Navigation";

import "./Profile.scss";

//
//  TODO: handle empty image-src for avatar
//

const Profile = (props) => {

  useEffect(() => {
    document.title = global.config.app.name + " - Profile";
    ReactGA.pageview("/profile");
  }, []);

  const reload = () => {
    window.location.reload();
  };

  return (
    <div id="profile-wrapper">
      <Navigation />
      <div className="row">
        <div className="col">
          <div className="body-wrapper">
            <h2>Profile</h2>
            <p>
              <Image
                roundedCircle
                className="profile-avatar"
                src={props.user.avatar}
                alt="Profile avatar"
                referrerPolicy="no-referrer"
              />
            </p>
            <p>
              Name: <strong>{props.user.name}</strong>
            </p>
            <p>
              Email: <strong>{props.user.email}</strong>
            </p>
            <p>
              <span onClick={reload} className="btn-as-link">
                Check for updates
              </span>
            </p>
            <p>
              <LogoutButton logout={props.logout} id="logout-button-profile" />
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

Profile.propTypes = {
  user: PropTypes.object.isRequired,
  logout: PropTypes.func.isRequired,
};

export default Profile;
