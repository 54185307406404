import React, { Component } from "react";
import "./../../config";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";

// Google Analytics
import ReactGA from "react-ga";

// Bootstrap
import Container from "react-bootstrap/Container";

// Components
import Service from "../../api/Service";
import Home from "../Home/Home";
import Check from "../Check/Check";
import Edit from "../Edit/Edit";
import Profile from "../Profile/Profile";
import Welcome from "../Welcome/Welcome";
import NotFound404 from "../NotFound404/NotFound404";

// Contexts
import UserProvider from "../../context/UserProvider";

// App-components
import Header from "./Header";
import Footer from "./Footer";

// Style
import "./App.scss";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      userIsLoggedIn: false,
      user: {},
    };
    ReactGA.initialize(global.config.app.trackingCode, { testMode: process.env.NODE_ENV === 'test' });

    // Handle user-object & login
    if (localStorage.hasOwnProperty("user") === false) {
      console.log("No user in localStorage, will render LoginButton");
    } else {
      console.log("User in localStorage, using localStorage data");
      let userInLocalStorage = JSON.parse(localStorage.getItem("user"));
      this.state = {
        userIsLoggedIn: true,
        user: {
          uid: userInLocalStorage.uid,
          auth_uid: userInLocalStorage.googleId,
          name: userInLocalStorage.name,
          email: userInLocalStorage.email,
          avatar: userInLocalStorage.imageUrl,
          list: userInLocalStorage.list,
        },
      };
    }
  }

  componentDidMount() {
    this.setState({
      loading: false,
    });
  }

  loginSuccess = (response) => {
    Service.getUser(response.profileObj.googleId)
      .then((apiResponse) => {
        if (apiResponse.error) {
          // Error from API
          // TODO: error management in React
          console.error(response); // TODO remove this logging
          alert("Error from API: " + apiResponse.message);
        } else {
          response.profileObj.uid = apiResponse.uid;
          response.profileObj.list = apiResponse.list;
          localStorage.setItem("user", JSON.stringify(response.profileObj));
          this.setState({
            userIsLoggedIn: true,
            user: {
              uid: apiResponse.uid,
              auth_uid: response.profileObj.googleId,
              name: response.profileObj.name,
              email: response.profileObj.email,
              avatar: response.profileObj.imageUrl,
              list: apiResponse.list,
            },
          });
          ReactGA.event({
            category: "User",
            action: "Login",
            label: "User login"
          });
        }
      })
      .catch((err) => {
        //TODO handle this ?
        console.error(err);
      });
  };

  loginFailure = (response) => {
    console.log("App.loginFailure called", response);
    //
    //  TODO: make this shit work. Complicated by login-button in header
    //

    // console.log(response);
    // console.log(typeof response.details);
    // if (typeof response.details === "string") {
    //   console.log("is a string, show error: ", response.details);
    //   return <p>error: {response.details}</p>;
    // } else {
    //   return <p>NO error</p>;
    // }
    return false;
  };

  forceLogout = () => {
    if (window.gapi) {
      const auth2 = window.gapi.auth2.getAuthInstance();
      if (auth2 != null) {
        auth2.signOut().then(auth2.disconnect());
      }
    } else {
      console.error("No windows.gapi");
    }
    this.logoutSuccess();
    this.forceUpdate();
    ReactGA.event({
      category: "User",
      action: "Logout",
      label: "User logout"
    });
    window.location.reload(true);
  };

  logoutSuccess = () => {
    localStorage.clear();
    this.setState({
      userIsLoggedIn: false,
      user: {},
    });
  };

  PrivateRoute = ({ children, ...rest }) => {
    return (
      <Route
        {...rest}
        render={({ location }) =>
          this.state.userIsLoggedIn ? (
            children
          ) : (
            <Redirect
              to={{
                pathname: "/",
              }}
            />
          )
        }
      />
    );
  };

  render() {
    return (
      <UserProvider value={this.state.user}>
        <Router>
          <div id="wrapper">
            <Container fluid>
              <Header
                userIsLoggedIn={this.state.userIsLoggedIn}
                user={this.state.user}
                loginSuccess={this.loginSuccess}
                loginFailure={this.loginFailure}
                loginLabel="Login"
              />
              <Switch>
                <Route exact path="/">
                  <Welcome
                    userIsLoggedIn={this.state.userIsLoggedIn}
                    loginSuccess={this.loginSuccess}
                    loginFailure={this.loginFailure}
                  />
                </Route>
                <this.PrivateRoute exact path="/home">
                  <Home user={this.state.user} logout={this.forceLogout} />
                </this.PrivateRoute>
                <this.PrivateRoute exact path="/check">
                  <Check user={this.state.user} />
                </this.PrivateRoute>
                <this.PrivateRoute exact path="/edit">
                  <Edit user={this.state.user} />
                </this.PrivateRoute>
                <this.PrivateRoute exact path="/profile">
                  <Profile user={this.state.user} logout={this.forceLogout} />
                </this.PrivateRoute>
                <Route path="*">
                  <NotFound404 />
                </Route>
              </Switch>
              <Footer />
            </Container>
          </div>
        </Router>
      </UserProvider>
    );
  }
}

export default App;
