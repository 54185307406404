module.exports = (items, searchQuery) => {
  const output = [];
  items.map((group) => {
    const groupResult = [];
    group.items.map((item) => {
      if (item.name.toLowerCase().includes(searchQuery)) {
        groupResult.push(item);
      }
      return true;
    });
    if (groupResult.length) {
      output.push({
        id: group.id,
        items: groupResult,
        name: group.name,
      });
    }
    return true;
  });
  return output;
};
