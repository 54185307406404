import React from "react";
import PropTypes from "prop-types";
import CheckBox from "../App/CheckBox";

const EditItem = (props) => {
  return (
    <li key={props.item.id} className="item-wrapper">
      <div className="inner">
        <CheckBox
          item={props.item}
          checked={props.item.active}
          groupId={props.groupId}
          onChange={props.activateItem}
          iconChecked={["fas", "check-circle"]}
          iconUnchecked={["far", "circle"]}
        />{" "}
        <span className="name">{props.item.name}</span>
        <span
          className="remove-item float-right"
          onClick={() => props.removeItem(props.item)}
        >
          &times;
        </span>
      </div>
    </li>
  );
};

EditItem.propTypes = {
  groupId: PropTypes.string.isRequired,
  item: PropTypes.object.isRequired,
  removeItem: PropTypes.func.isRequired,
  activateItem: PropTypes.func.isRequired,
};

export default EditItem;
