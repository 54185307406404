import React, { Component } from "react";

class ErrorBoundary extends Component {
  state = {
    error: "",
    errorInfo: "",
    hasError: false,
  };

  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({
      hasError: true,
      errorMessage: error,
    });
    console.error(error, errorInfo);
  }

  componentDidMount() {
    window.onerror = (error) => {
      this.setState({ hasError: true });
    };
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="alert alert-danger m-4" role="alert">
          <h4 className="alert-heading">Something went wrong.</h4>
          <p className="mb-0">
            <a
              className="alert-link"
              style={{ cursor: "pointer" }}
              href="#section"
              onClick={() => {
                window.location.reload();
              }}
            >
              Try reloading the page
            </a>
          </p>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
