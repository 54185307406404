import React, { useState } from "react";
import PropTypes from "prop-types";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

const AddGroup = (props) => {

  const [showAddGroupForm, setShowAddGroupForm] = React.useState(false);

  const [currentState, setCurrentState] = useState({
    name: ""
  });

  let initialState = {
    name: ""
  };

  let handleChange = (event) => {
    event.preventDefault();
    const value = event.target.value;
    setCurrentState({
      name: value
    });
  };

  let submitForm = (event) => {
    event.preventDefault();
    document.activeElement.blur();
    props.addGroup(currentState.name);
    setCurrentState(initialState);
    setShowAddGroupForm(false);
    return false;
  };

  let toggleDisplayAddGroup = (event) => {
    setShowAddGroupForm(!showAddGroupForm);
    setCurrentState(initialState);
    return false;
  };

  return (
    <div className="add-group-wrapper">
      {showAddGroupForm === false ? (
        <React.Fragment>
          <div className="float-right">
            <Button className="show-add-group-button" onClick={(event) => toggleDisplayAddGroup(event)}>
              New Group
            </Button>
          </div>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <form onSubmit={(event) => submitForm(event)}>
            <table className="add-group-table">
              <tbody>
                <tr>
                  <td className="add-group-input-cell">
                    <Form.Control
                      type="text"
                      className="add-group-input"
                      value={currentState.name}
                      onChange={handleChange}
                      autoFocus
                    />
                  </td>
                  <td className="add-group-button-cell">
                    <Button
                      type="submit"
                      className="add-group-button"
                      onClick={(event) => submitForm(event)}
                    >
                      Add group
                    </Button>
                  </td>
                  <td className="add-group-cancel-cell">
                    <Button
                      variant="dark-outline"
                      className="add-group-cancel"
                      onClick={(event) => toggleDisplayAddGroup(event)}
                    >
                      Cancel
                    </Button>
                  </td>
                </tr>
              </tbody>
            </table>
          </form>
        </React.Fragment>
      )}
    </div>
  );
}

AddGroup.propTypes = {
  addGroup: PropTypes.func.isRequired,
};

export default AddGroup;
